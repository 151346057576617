import React from 'react'
import { Layout, Stack, Main } from '@layout'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import CardList from '@components/CardList/CardList'
import { Hero } from '@layout'
import HeroComponent from '../../../gatsby-theme-flexiblog-personal/src/components/Hero/Hero'
import { getPartnersData } from '../helpers/nr-partners.helper'
import { getStaticPageSeoDetails } from '@utils-blog'
import MemphisPattern from '@components/MemphisPattern/MemphisPattern'

/**
 * Shadow me to add your own content
 */

export default props => {
  const data = getPartnersData()

  let seoData = getStaticPageSeoDetails('shared.partners')

  const partnersSeoData = {
    ...seoData,
    Partners: {
      ...seoData?.Partners,
      keywords: seoData?.Partners?.keywords?.split('\n')
    }
  }

  return (
    <Layout {...props}>
      <Seo
        title={partnersSeoData?.Partners?.metaTitle}
        description={partnersSeoData?.Partners?.metaDescription}
        keywords={partnersSeoData?.Partners?.keywords}
        thumbnail={partnersSeoData?.Partners?.metaImage}
      />
      <Hero full>
        <Hero sx={{ padding: `50px 0` }}>
          <HeroComponent
            customHeroFlag={true}
            heroType={'partnerHero'}
            {...props}
            callFrom='Partners'
          />
        </Hero>
        <MemphisPattern customHeroBgFlag={true} />
      </Hero>
      <Stack>
        <Main>
          <Divider />
          <CardList
            variant={['horizontal-md', 'horizontal']}
            title='Partners'
            nodes={data.nodes}
            columns={[1, 2, 2]}
            isPartner={true}
          />
        </Main>
      </Stack>
    </Layout>
  )
}
