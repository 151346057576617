import { useStaticQuery, graphql } from 'gatsby'

export const getPartnersData = () => {
  const { allStrapiPartner } = useStaticQuery(partnersDataQuery)
  return allStrapiPartner
}

const partnersDataQuery = graphql`
  query {
    allStrapiPartner(sort: { fields: date, order: DESC }) {
      totalCount
      nodes {
        id
        title
        slug
        excerpt {
          data {
            excerpt
          }
        }
        featuredImage {
          __typename
          ImageSharp_vertical: localFileImageSharp {
            gatsbyImageData
          }
        }
        body {
          data {
            body
          }
        }
        seo {
          id
          keywords
          metaDescription
          metaTitle
        }
      }
    }
  }
`
